<template>
  <container style="max-width: 800px;">
    <v-card>
      <v-app-bar flat color="white">
        <v-toolbar-title>{{ $capitalize($tc("model.reminder_reminders_title")) }}</v-toolbar-title>
      </v-app-bar>
      <ReminderList id="page" has-counter />
    </v-card>
  </container>
</template>

<script>
import { mapActions, mapState} from 'vuex';

import ReminderList from '@/components/ReminderList';

export default {
  components: {
    ReminderList
  },
  data() {
    return {
      filter: 'all',
    }
  },
  computed: {
    today_reminders() {
      return this.active_reminders.filter(reminder => reminder.diff_day == 'today');
    },
    tomorrow_reminders() {
      return this.active_reminders.filter(reminder => reminder.diff_day == 'tomorrow');
    },
    future_reminders() {
      return this.active_reminders
              .filter(reminder => reminder.diff_day == 'future');
    },
    filtered_today_reminders() {
      if (this.filter == 'all' || this.filter == 'today') {
        return this.today_reminders;
      }

      return [];
    },
    filtered_tomorrow_reminders() {
      if (this.filter == 'all' || this.filter == 'tomorrow') {
        return this.tomorrow_reminders;
      }

      return [];
    },
    filtered_future_reminders() {
      if (this.filter == 'all' || this.filter == 'future') {
        return this.future_reminders;
      }

      return [];
    },
    filtered_previous_reminders() {
      if (this.filter == 'all' || this.filter == 'previous') {
        return this.previous_reminders;
      }

      return [];
    },
    all_reminders_count() {
      return this.today_reminders.length 
              + this.tomorrow_reminders.length 
              + this.future_reminders.length 
              + this.previous_reminders.length;
    },
    ...mapState('reminder', ['active_reminders', 'previous_reminders']),
  },
  methods: {
    ...mapActions('reminder', ['loadActiveReminders', 'loadInitialPreviousReminders']),
  },
  created() {
    this.loadActiveReminders();
    this.loadInitialPreviousReminders();
  }
}
</script>

<style>

</style>